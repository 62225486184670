import { VoucherService } from '@vitafy/storefront-api-contracts-fetch';
import { createContext, useContext, useEffect, useState } from 'react';

import { getSfApiClient } from '@utils/helpers';

import type {
  TCustomPriceVoucher,
  TVoucher,
  TVoucherWithProductFields
} from '@vitafy/storefront-api-contracts';

// gets information and expose about custom price and voucher
// parameters `cph` and `vc`

export type customPriceContext = {
  customPrice?: TCustomPriceVoucher | null;
  productVoucher?: TVoucherWithProductFields | null;
  voucher?: TVoucher | null;
};

const CustomPriceContext = createContext<customPriceContext | undefined>(undefined);

export const CustomPriceContextProvider: React.FC<{
  children: React.ReactNode;
  storeCode: string;
  productId?: number;
}> = ({ children, storeCode, productId }) => {
  const [customPrice, setCustomPrice] = useState<TCustomPriceVoucher | null>(null);
  const [voucher, setVoucher] = useState<TVoucher | null>(null);
  const [productVoucher, setProductVoucher] = useState<TVoucherWithProductFields | null>(null);

  // IF NEEDED, UNCOMMENT AND ADJUST
  // Commented out to mimicking the old behavior - ignore cookies

  // if (voucherCode) {
  //   Cookies.set('vc', voucherCode.toString());
  // } else {
  //   const cookieVc = Cookies.get('vc');

  //   if (typeof cookieVc === 'string') {
  //     const parsedCookieVc = Number(cookieVc);
  //     if (isNaN(parsedCookieVc)) {
  //       throw new Error('Invalid voucher code in cookie.');
  //     }

  //     voucherCode = parsedCookieVc;
  //   }
  // }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const cph = params.get('cph');
    const vc = Number(params.get('vc'));

    const fetchCustomPrice = async () => {
      const sfApiClient = await getSfApiClient();

      if (productId && cph) {
        const res = await sfApiClient.customPrice.getCustomPrice({
          params: {
            cph,
            storeCodeOrId: storeCode,
            productId
          }
        });
        if (res.status !== 200) {
          // TODO: error must be logged
          return;
        }
        const customPrice = res.body;
        setCustomPrice(customPrice);
      }
    };

    const fetchVoucher = async () => {
      if (vc && !isNaN(vc)) {
        const voucher = await VoucherService.voucherControllerGetVoucher({
          vc,
          storeCodeOrId: storeCode
        });
        setVoucher(voucher);
      }
    };

    const fetchProductVoucher = async () => {
      if (productId && vc && !isNaN(vc)) {
        const productVoucher = await VoucherService.voucherControllerGetProductVoucher({
          vc,
          productId,
          storeCodeOrId: storeCode
        });
        setProductVoucher(productVoucher);
      }
    };

    fetchCustomPrice();
    fetchVoucher();
    fetchProductVoucher();
  }, [storeCode, productId]);

  return (
    <CustomPriceContext.Provider value={{ customPrice, voucher, productVoucher }}>
      {children}
    </CustomPriceContext.Provider>
  );
};

export const useCustomPriceContext = () => {
  const context = useContext(CustomPriceContext);
  if (!context) {
    throw new Error('CustomPriceContext must be used within a CustomPriceContext.Provider');
  }
  return context;
};

export { CustomPriceContext };

import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { useResizeListener } from '@common/hooks/useResizeListener';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined
  });

  const handleResize = debounce(() => {
    if (window.visualViewport) {
      setWindowSize({
        width: window.visualViewport.width,
        height: window.visualViewport.height
      });
    } else {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
  }, 100);

  useResizeListener(handleResize);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  return windowSize;
}

/* Handles the add to cart functionality and keeps track of loading state and success state */

import { useContext, useEffect, useState } from 'react';

import useTranslate from '@common/hooks/useTranslate';
import { trackGAAddToCart } from '@common/tracking';
import { sendGAEvent } from '@common/utlis';
import { useNotificationContext } from '@stories/molecules/Notification';
import { PageContext } from '@stories/templates/Context/pageContext';
import { useProductWithOptionsContext } from '@stories/utlis/context/ProductWithOptionsContext';
import useCart from '@stories/utlis/useCart';

import type { AddToCartResponse } from '@stories/utlis/useCart';

// import { errorMessageDisplayedAtom } from '@stories/utlis/globalState';

type handleAddProductClickSignature = (AddProductToCartParams) => Promise<AddToCartResponse | void>;

const translations = {
  "Oops! We couldn't add the item to your cart. Please try again.": {
    de_DE: 'Ups! Wir konnten den Artikel nicht in Ihren Warenkorb legen. Bitte versuchen Sie es erneut.',
    nl_NL: 'Oeps! We konden het item niet aan uw winkelwagen toevoegen. Probeer het opnieuw.'
  }
};

const useAddToCart = (): {
  isLoading: boolean;
  isSuccess: boolean;
  handleAddProductClick: handleAddProductClickSignature;
} => {
  const [, addProduct] = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { isProductConfigured, name, price, baseprice, productOptions } = useProductWithOptionsContext();
  const { showProductConfiguration } = useContext(PageContext);
  const { showNotification } = useNotificationContext();
  const translate = useTranslate(translations);

  // const [_, setErrorMessageDisplayed] = useAtom(errorMessageDisplayedAtom);

  const handleAddProductClick: handleAddProductClickSignature = async (addToCartParameters) => {
    const { productId, productConfiguration, productType, quantity } = addToCartParameters;
    if (!isProductConfigured) {
      showProductConfiguration({ productId, name, price, baseprice, type: productType }, productOptions);
      return;
    }

    setIsLoading(true);
    return addProduct({ productId, productConfiguration, productType, quantity })
      .then(({ status }) => {
        if (status === 'success') {
          trackGAAddToCart({
            id: productId,
            quantity: 1,
            name,
            price,
            buttonVariant: 'hit list view'
          });
          setIsSuccess(true);
        } else {
          throw new Error('Error adding product to cart');
        }
      })
      .catch((error) => {
        sendGAEvent({
          category: 'Add to cart',
          action: 'Error',
          label: `{ "productId": ${productId}, "error": "${error}" }`
        });
        showNotification(
          translate("Oops! We couldn't add the item to your cart. Please try again."),
          'error'
        );
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    window.setTimeout(() => setIsSuccess(false), 10000);
  }, [isSuccess]);

  return { isLoading, isSuccess, handleAddProductClick };
};

export default useAddToCart;

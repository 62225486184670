import Color from 'color';
import merge from 'lodash/merge';

import { media } from '@stories/theming/settings';
import { ASSET_URL } from '@utils/constants';

import defaultTheme, { button as defaultButton, color as defaultColor } from './default';

const fontFaceHeadline = `@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(${ASSET_URL}/asset/fonts/MuseoSans/museo_sans_regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Museo Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(${ASSET_URL}/asset/fonts/MuseoSans/museo_sans_bold.woff2) format('woff2');
}
`;

const fontFaceCopy = '';

const fontFaceAccent = '';

const color = merge({}, defaultColor, {
  copy: 'hsl(0 0% 20%)',
  highlight: 'hsl(194 100% 40%)',
  primary: 'hsl(194 100% 40%)',
  promotion: 'hsl(0 100% 36%)',
  secondary: 'hsl(0 0% 20%)',
  hover: {
    highlight: Color('hsl(194 100% 40%)').darken(0.1).hsl().string(),
    primary: Color('hsl(194 100% 40%)').darken(0.1).hsl().string(),
    copy: 'hsl(194 100% 40%)'
  }
});

const font = {
  family: {
    headline: "'Museo Sans', sans-serif",
    copy: "'Museo Sans', sans-serif",
    accent: "'Museo Sans', sans-serif"
  },
  size: {
    copy: '16px',
    small: '14px',
    footnote: '12px',
    tiny: '12px'
  },
  face: {
    headline: fontFaceHeadline,
    copy: fontFaceCopy,
    accent: fontFaceAccent
  },
  copy: {
    font: '300 16px/140% "Museo Sans", sans-serif'
  },
  small: {
    font: '300 14px/140% "Museo Sans", sans-serif'
  },
  footnote: {
    font: '300 12px/140% "Museo Sans", sans-serif'
  },
  tiny: {
    font: '300 12px/140% "Museo Sans", sans-serif'
  },
  link: {
    textDecoration: 'underline',
    textDecorationColor: 'hsl(0, 0%, 50%)',
    textUnderlineOffset: '3px',
    ':hover': {
      color: color.primary,
      textDecorationColor: 'currentColor'
    }
  }
};

const badge = {
  verticalAlign: '-1px',
  core: {
    alignItems: 'center',
    border: 'thin solid transparent',
    borderRadius: '0.25rem',
    display: 'inline-block',
    fontSize: '11px',
    fontWeight: '500',
    justifyContent: 'center',
    lineHeight: '100%',
    padding: '.125rem 0.5rem',
    textTransform: 'uppercase'
  },
  new: {
    backgroundColor: color.secondary,
    borderColor: color.secondary,
    color: 'white'
  },
  bestseller: {
    backgroundColor: 'white',
    borderColor: color.secondary,
    color: color.secondary
  },
  discount: {
    backgroundColor: color.promotion,
    borderColor: color.promotion,
    color: 'white'
  },
  freeShipping: {
    backgroundColor: 'white',
    borderColor: color.secondary,
    color: color.secondary
  },
  freeText: {
    backgroundColor: 'white',
    borderColor: color.secondary,
    color: color.secondary
  }
} as const;

const headline = {
  h1: {
    font: '400 32px/110% "Museo Sans", sans-serif',
    letterSpacing: '-0.01em',
    [media('desktop')]: {
      fontSize: '42px'
    }
  },
  h2: {
    font: '400 28px/110% "Museo Sans", sans-serif',
    letterSpacing: '0.02em',
    [media('desktop')]: {
      fontSize: '36px'
    }
  },
  h3: {
    font: '700 22px/110% "Museo Sans", sans-serif',
    letterSpacing: '0.02em',
    [media('desktop')]: {
      fontSize: '26px'
    }
  },
  h4: {
    font: '700 18px/110% "Museo Sans", sans-serif',
    [media('desktop')]: {
      fontSize: '22px'
    }
  },
  h5: {
    font: '700 16px/120% "Museo Sans", sans-serif',
    letterSpacing: '0.02em',
    [media('desktop')]: {
      fontSize: '20px'
    }
  }
};

const headerNavigation = {
  container: {
    color: color.secondary,
    lineHeight: '1.75em'
  },
  firstLevelItems: {
    lineHeight: '1.75em',
    ':hover': {
      color: color.primary
    }
  },
  secondLevelItems: {
    ':hover': {
      color: color.primary
    },
    fontWeight: 'bold',
    lineHeight: '2em'
  },
  thirdLevelItems: {
    lineHeight: '1.75em',
    ':hover': {
      color: color.primary
    }
  }
};

const button = merge({}, defaultButton, {
  primary: {
    solid: {
      backgroundColor: color.primary,
      border: `1px solid ${color.primary}`,
      borderRadius: '3px',
      color: 'white',
      letterSpacing: 0,
      textTransform: 'none' as const,
      ':hover': {
        backgroundColor: color.hover.primary,
        borderColor: 'white'
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: `1px solid ${color.primary}`,
      color: color.primary,
      textTransform: 'none' as const,
      ':hover': {
        backdropFilter: 'invert(.08)'
      }
    }
  },
  secondary: {
    solid: {
      backgroundColor: color.secondary,
      border: `1px solid ${color.secondary}`,
      color: 'white',
      textTransform: 'none' as const,
      ':hover': {
        backgroundColor: Color(color.secondary).lighten(0.5).hsl().string(),
        borderColor: 'white'
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: `1px solid ${color.secondary}`,
      borderColor: color.secondary,
      color: color.secondary,
      textTransform: 'none' as const,
      ':hover': {
        backdropFilter: 'invert(.08)'
      }
    }
  },
  tertiary: {
    solid: {
      backgroundColor: color.grey.g92,
      border: `1px solid ${color.grey.g92}`,
      color: color.primary,
      textTransform: 'none' as const,
      ':hover': {
        backgroundColor: Color(color.grey.g92).darken(0.05).hsl().string(),
        borderColor: 'white'
      }
    },
    outline: {
      backgroundColor: 'transparent',
      border: '1px solid white',
      color: 'white',
      textTransform: 'none' as const,
      ':hover': {
        backdropFilter: 'invert(.08)'
      }
    }
  },
  small: {
    compensateY: '0',
    font: `300 14px/16px ${font.family.copy}`,
    padding: '6px 1.5rem'
  },
  regular: {
    compensateY: '0',
    font: `300 16px/18px ${font.family.copy}`,
    padding: '.5rem 1.5rem'
  },
  big: {
    compensateY: '0',
    font: `300 18px/18px ${font.family.copy}`,
    padding: '.75rem 1.5rem'
  }
});

const theme = merge({}, defaultTheme, {
  storeId: 'vitafy',
  badge,
  button,
  color,
  font,
  headerNavigation,
  headline
});

export default theme;

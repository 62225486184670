/** @jsxImportSource @emotion/react */

import Image from 'next/image';

export type MediaLink = {
  name: string;
  subheading?: string;
  thumbnail?: string | null;
  url: string;
  sizes?: string;
};

export const MediaLink: React.FC<MediaLink> = ({ url, thumbnail, name, subheading, sizes }) => {
  return (
    <div
      css={(t) => ({
        borderRadius: '4px',
        padding: '.75rem .5rem',
        position: 'relative',
        ':hover': { backgroundColor: t.color.grey.g97, img: { transform: 'scale(1.05)' } }
      })}
    >
      <h3 css={(t) => ({ ...t.header.navigation.secondLevelItems, ':hover': {} })}>
        <a
          href={url}
          css={{
            display: 'block',
            '::after': {
              bottom: 0,
              content: "''",
              display: 'block',
              left: 0,
              position: 'absolute',
              right: 0,
              top: 0,
              zIndex: 1
            }
          }}
        >
          {name}
        </a>
      </h3>
      {thumbnail && (
        <div>
          <div css={{ aspectRatio: '16 / 9', overflow: 'hidden', position: 'relative' }}>
            <Image
              src={thumbnail}
              alt={name}
              fill
              sizes={sizes ?? '235px'}
              css={{ transition: 'transform 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98)' }}
            />
          </div>
          {subheading && (
            <div css={(t) => ({ fontSize: t.font.size.small, marginTop: '.75rem' })}>{subheading}</div>
          )}
        </div>
      )}
    </div>
  );
};

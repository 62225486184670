/** @jsxImportSource @emotion/react */
import ChevronDownSvg from './chevron_down.svg';

import type { CssProps } from '@stories/@types/custom';
import type { ChangeEventHandler } from 'react';

type SelectProps = {
  description?: string;
  name?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrs?: { [key: string]: string | any };
  size?: 'regular' | 'big';
  options?: {
    label?: string;
    value?: string;
    checked?: boolean;
    id?: number;
    disabled?: boolean;
  }[];
} & CssProps;

const chevronClass = {
  display: 'block',
  height: '1.5rem',
  opacity: 0.8,
  position: 'absolute',
  right: '0.75rem',
  top: 'calc(50% - 10px)',
  zIndex: 1,
  width: '1.5rem'
};

const Select: React.FC<SelectProps> = ({
  cssProps,
  description = '',
  name,
  onChange,
  options,
  size = 'regular',
  attrs = {}
}) => {
  const selectedValue = options?.find((option) => option.checked)?.value;

  const selectClassFn = (t) =>
    ({
      backgroundColor: 'transparent',
      border: `thin solid ${t.color.grey.g40}`,
      borderRadius: t?.button?.primary?.solid?.borderRadius || '3px',
      color: 'currentColor', // iOS blue
      cursor: 'default',
      display: 'inline-block',
      MozAppearance: 'none',
      padding: '10px 40PX 8px 13px',
      position: 'relative',
      WebkitAppearance: 'none',
      zIndex: 2,
      minHeight: t.button[size].minHeight
    }) as const;

  return (
    <label
      css={() => ({
        display: 'inline-block',
        position: 'relative',
        ...cssProps
      })}
    >
      {description && (
        <span
          css={() => ({
            display: 'block',
            marginBottom: '0.25rem'
          })}
        >
          {description}
        </span>
      )}
      <div
        css={() => ({
          display: 'block',
          position: 'relative',
          overflow: 'hidden'
        })}
      >
        <select name={name} css={selectClassFn} onChange={onChange} {...{ ...attrs }} value={selectedValue}>
          {options?.map(({ label, value, disabled }) => (
            <option value={value} key={value} disabled={disabled}>
              {label}
            </option>
          ))}
        </select>
        <ChevronDownSvg css={chevronClass} />
      </div>
    </label>
  );
};

export default Select;

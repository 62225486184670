import { createContext, useContext } from 'react';

import type { ProductType } from '@stories/organisms/HitListView/ReduceProps';
import type { TProductES } from '@vitafy/common-schemas';

export type TProductOption = Omit<NonNullable<TProductES['product_options']>[number], 'selection'> & {
  selection: Array<
    NonNullable<TProductES['product_options']>[number]['selection'][number] & {
      selected?: boolean;
    }
  >;
};

export type TProductOptions = TProductOption[];

export const ProductWithOptionsContext = createContext<
  | {
      baseprice: string;
      name: string;
      price: string;
      productId: string;
      productOptions: TProductOptions;
      setProductOptions: (options: TProductOptions) => void;
      productType?: ProductType | null;
    }
  | undefined
>(undefined);

const isProductConfigured = (options: TProductOptions) =>
  options.every(
    ({ selection }) => selection.length === 1 || selection.findIndex(({ selected }) => selected === true) > -1
  );

const getProductConfiguartion = (productOptions: TProductOptions): TProductConfiguration | undefined => {
  if (!isProductConfigured(productOptions)) {
    return undefined;
  }

  return productOptions.reduce(
    (acc, { id, selection }) =>
      selection.length === 1
        ? { ...acc, [id]: selection[0].id }
        : { ...acc, [id]: selection.find(({ selected }) => selected === true)?.id },
    {}
  );
};
export type TProductConfiguration = {
  [id: number]: number;
};
type TUseProductWithOptionsContext = {
  isContextDefined: boolean;
  isProductConfigured: boolean;
  productConfiguration?: { [id: number]: number };
  productOptions: TProductOptions;
  productId?: string;
  name?: string;
  price?: string;
  baseprice?: string;
  setProductOptions: (TProductOptions) => void;
  productType?: ProductType | null;
};

export const useProductWithOptionsContext: () => TUseProductWithOptionsContext = () => {
  const context = useContext(ProductWithOptionsContext);
  if (typeof context === 'undefined') {
    return {
      productId: undefined,
      isContextDefined: false,
      isProductConfigured: true,
      productConfiguration: undefined,
      productOptions: [],
      setProductOptions: () => {
        return;
      }
    };
  }

  return {
    ...context,
    isContextDefined: true,
    isProductConfigured: isProductConfigured(context.productOptions),
    productConfiguration: getProductConfiguartion(context.productOptions),
    setProductOptions: context.setProductOptions
  };
};

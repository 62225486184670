/** @jsxImportSource @emotion/react */

import { useContext, useEffect } from 'react';

import { sendGAEvent } from '@common/utlis';
import CloseIconButton from '@stories/atoms/CloseIconButton';
import { zIndexes } from '@stories/atoms/utils';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media, mediaDown } from '@stories/theming/settings';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';

export type DialogueOverlayProps = {
  children: React.ReactNode;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const containerStyle: Interpolation<Theme> = {
  alignItems: 'stretch',
  backgroundColor: 'white',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  boxShadow: '0px -4px 8px hsl(0 0% 0% / 15%), 0 -8px 25px hsl(0 0% 0% / 10%)',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  left: 0,
  maxHeight: '85vh',
  padding: '4rem 1rem',
  position: 'fixed',
  transform: 'translate(0%, 100%)',
  transition: 'transform 250ms ease-out',
  width: '100vw',
  zIndex: zIndexes.darkOverlay + 1,
  [mediaDown('desktop')]: {
    borderRadius: '14px 14px 0 0',
    padding: '1.5rem var(--offset) 0',
    width: '100vw'
  },
  [media('desktop')]: {
    borderRadius: '14px 0 0 14px',
    height: '100vh',
    left: 'auto',
    maxHeight: 'none',
    maxWidth: '28rem',
    right: '0',
    transform: 'translate(100%, 0%)'
  }
} as const;

export const DialogueOverlay: React.FC<DialogueOverlayProps> = ({ children, open, setOpen }) => {
  const { hideOverlay, showOverlay } = useContext(PageContext);

  useEffect(() => {
    if (open) {
      window.history.pushState(null, '', '#overlay');
      showOverlay({ callback: () => setOpen(false), closeOnClick: true });

      const scrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100dvh';
      document.body.style.width = '100vw';
      document.body.style.top = `-${scrollY}px`;
    } else {
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
      document.body.style.width = 'auto';
      window.scrollTo({ left: 0, top: parseInt(scrollY || '0') * -1, behavior: 'instant' });
    }

    window.onpopstate = () => {
      if (window.location.hash !== '#overlay') {
        sendGAEvent({ category: 'Overlay', action: 'close', label: 'back' });
        hideOverlay();
      }
    };

    return () => {
      window.onpopstate = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <aside
      css={containerStyle}
      style={{ ...(open && { transform: 'translate(0)', transition: 'transform 250ms 250ms ease-out' }) }}
    >
      <CloseIconButton
        cssProps={{ position: 'absolute', top: '14px', right: '13px', zIndex: '1' }}
        onClick={() => {
          hideOverlay();
          setOpen(false);
        }}
      ></CloseIconButton>
      {children}
    </aside>
  );
};

/** @jsxImportSource @emotion/react */
import Image from 'next/image';

import { container } from '@stories/atoms/Grid';
import Link from '@stories/atoms/Link';
import { media, mq } from '@stories/theming/settings';

import type { NewFooterFragment } from '@gql/hygraph/graphql';

export type TNewFooter = NewFooterFragment;
export type TNewFooterSection = NewFooterFragment['sections'][0];

const BottomBlock: React.FC<TNewFooter> = ({ bottomLinks, bottomText, bottomUsPs }) => {
  return (
    <div css={{ ...container }}>
      {bottomUsPs && (
        <ul
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '1.25rem',
            paddingBottom: '1.5rem !important' /*component renderer*/
          }}
        >
          {bottomUsPs.map((usp) => (
            <li
              key={usp}
              css={(t) =>
                mq({
                  backgroundColor: 'hsl(0 0% 97% / 8%)',
                  borderRadius: '8px',
                  color: t.color.grey.g92,
                  flex: ['100% 0 0', '45% 1 1', null, '22% 1 1'],
                  fontSize: t.font.size.footnote,
                  padding: '1rem !important', //because of component renderer
                  textAlign: 'center'
                })
              }
            >
              {usp.split(' ').map((word, index) =>
                index === 0 ? (
                  <div
                    key={index}
                    css={(t) => ({
                      color: t.color.grey.g97,
                      fontWeight: 'bold',
                      lineHeight: '120%',
                      textTransform: 'uppercase'
                    })}
                  >
                    {word}
                  </div>
                ) : (
                  ` ${word} `
                )
              )}
            </li>
          ))}
        </ul>
      )}
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          ...container,
          gap: '1.25rem'
        }}
      >
        <div
          css={(t) => ({
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            color: t.color.grey.copy
          })}
        >
          {bottomLinks?.map((item) => (
            <a
              key={item.label}
              href={item?.href || ''}
              css={(t) => ({
                '&:hover': { textDecoration: 'underline !important' },
                fontSize: t.font.size.small
              })}
            >
              <p>{item.label}</p>
            </a>
          ))}
        </div>
        <div>{bottomText}</div>
      </div>
    </div>
  );
};

const LeftChildDiv: React.FC<TNewFooter> = ({
  mainLogo,
  subtitle,
  descriptionText,
  socialMediaLinks,
  socialMediaHeadline
}) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexDirection: 'column',
        gap: '1.25rem'
      }}
    >
      <div css={{ [media('desktop')]: { width: '80%' } }}>
        {mainLogo?.url && (
          <Image src={mainLogo?.url} unoptimized width={200} height={44} alt={mainLogo.alt}></Image>
        )}
      </div>
      <div>{subtitle}</div>
      <div css={(t) => ({ color: t.color.grey.copy, [media('desktop')]: { width: '80%' } })}>
        {descriptionText}
      </div>
      <div>
        <div>{socialMediaHeadline}</div>
        <div
          css={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '1rem',
            marginTop: '1rem'
          }}
        >
          {socialMediaLinks.map((socialMedia) => (
            <a
              key={socialMedia.link?.id}
              href={socialMedia.link?.href || ''}
              target={socialMedia.link?.blank ? '_blank' : 'self'}
            >
              {socialMedia.icon?.url && (
                <Image src={socialMedia?.icon?.url} unoptimized width={32} height={32} alt={'logo'}></Image>
              )}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

const RightChildDiv: React.FC<TNewFooter> = ({
  sections,
  paymentMethodHeadline,
  paymentMethodIcons,
  deliveryAgentHeadline,
  deliveryAgentIcon,
  trustedShopHeadline,
  trustedShopIcon
}) => {
  return (
    <div
      css={mq({
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: ['1.25rem', null, '3rem'],
        minWidth: '50%'
      })}
    >
      <div
        className="footer-navigation"
        css={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '2rem',
          gap: '1rem',
          [media('desktop')]: { flexDirection: 'row', justifyContent: 'space-between', marginTop: '1rem' }
        }}
      >
        {sections?.map((section) => <Section {...section} key={section.id}></Section>)}
      </div>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: '0.5rem'
        }}
      >
        <h4
          className="payment-methods-headline"
          css={{
            textTransform: 'uppercase',
            fontWeight: 'bold !important',
            borderBottom: '1px solid white',
            paddingBottom: '.5rem',
            marginBottom: '.5rem',
            width: '100%',
            [media('desktop')]: { border: 'none', marginBottom: '0' }
          }}
        >
          {paymentMethodHeadline}
        </h4>
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '0.5rem',
            [media('desktop')]: { width: '100%' }
          }}
        >
          {paymentMethodIcons?.map(({ alt, url }) => (
            <div key={url} css={{ height: '25px', position: 'relative' }}>
              <Image
                src={url}
                width={0}
                height={0}
                alt={alt}
                unoptimized
                style={{ height: '100%', width: 'auto' }}
              />
            </div>
          ))}
        </div>
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'space-between',
          gap: '2.5rem'
        }}
      >
        <div>
          <h4
            css={{
              textTransform: 'uppercase',
              fontWeight: 'bold !important',
              marginBottom: '.5rem !important'
            }}
          >
            {deliveryAgentHeadline}
          </h4>
          {deliveryAgentIcon && (
            <Image
              src={deliveryAgentIcon?.url}
              unoptimized
              width={110}
              height={16}
              alt={deliveryAgentIcon.alt}
            ></Image>
          )}
        </div>
        <div>
          <h4
            css={{
              textTransform: 'uppercase',
              fontWeight: 'bold !important',
              marginBottom: '.5rem !important'
            }}
          >
            {trustedShopHeadline}
          </h4>
          {trustedShopIcon && (
            <Image
              src={trustedShopIcon.url}
              width={40}
              height={40}
              alt={trustedShopIcon.alt}
              unoptimized
            ></Image>
          )}
        </div>
      </div>
    </div>
  );
};

const Section: React.FC<TNewFooterSection> = ({ links, headline }) => {
  return (
    <div>
      <h4
        css={() => ({
          fontWeight: 'bold !important',
          letterSpacing: '0.02em',
          paddingBottom: '.5rem !important',
          marginBottom: '.5rem !important',
          textTransform: 'uppercase',
          borderBottom: '1px solid white',
          [media('desktop')]: { border: 'none', paddingBottom: '0' }
        })}
      >
        {headline}
      </h4>
      <ul>
        {links?.map((item) => (
          <li
            css={(t) => ({
              lineHeight: '170%',
              color: t.color.grey.copy
            })}
            key={item.label}
          >
            <Link
              label={item.label}
              blank={!!item.blank}
              href={item?.href || ''}
              options={{ inverted: true }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const NewFooter: React.FC<TNewFooter> = (props) => {
  return (
    <footer
      css={(t) => ({
        backgroundColor: t.color.primary,
        color: 'white',
        position: 'relative',
        padding: '5rem 0'
      })}
    >
      <div
        css={{
          display: 'flex',
          flexWrap: 'wrap',
          ...container,
          marginBottom: '2.25rem',
          [media('desktop')]: { flexWrap: 'nowrap' }
        }}
      >
        <LeftChildDiv {...props} />
        <RightChildDiv {...props} />
      </div>
      <BottomBlock {...props}></BottomBlock>
    </footer>
  );
};

export default NewFooter;

import { Global, ThemeProvider } from '@emotion/react';
import { type CategoryListItemFromDBDto, type PageMetaDto } from '@vitafy/storefront-api-contracts-fetch';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module-defer';

import useTranslate from '@common/hooks/useTranslate';
import { sendGAEvent } from '@common/utlis';
import Overlay from '@stories/atoms/Overlay';
import { DialogueOverlayProvider } from '@stories/molecules/DialogueOverlay/dialogueOverlayContext';
import { NotificationContextProvider } from '@stories/molecules/Notification';
import NotificationProduct from '@stories/molecules/NotificationProduct';
import MobileNavigation from '@stories/organisms/MobileNavigation';
import NewFooter from '@stories/organisms/NewFooter';
import NewsletterSubscriptionLP from '@stories/organisms/NewsletterSubscriptionLP';
import ProductConfigurationOverlay from '@stories/organisms/ProductConfigurationOverlay';
import SearchDropDown from '@stories/organisms/SearchDropDown';
import USPCardPack from '@stories/organisms/USPCardPack/USPCardPack';
import VoucherBar from '@stories/organisms/VoucherBar';
import { PageContext } from '@stories/templates/Context/pageContext';
import globalCss from '@stories/theming/global';
import { CustomPriceContextProvider } from '@stories/utlis/context/CustomPriceContext/CustomPriceContext';
import useCart from '@stories/utlis/useCart';
import { ASSET_URL, FAVICONS } from '@utils/constants';
import { getFontsUrlsByStoreCode, getThemeByStoreCode } from '@utils/ui';

import type {
  GetNewsletterSubscriptionFormQuery,
  NextjsScriptLoadStrategy,
  StoreCodes
} from '@gql/hygraph/graphql';
import type { TNewFooter } from '@stories/organisms/NewFooter';
import type { StoreData } from '@utils/hygraph';
import type { THit } from '@vitafy/search-data-api-contracts';
import type { TTrustedShopsRatingResponse } from '@vitafy/storefront-api-contracts';

export type PageMeta = PageMetaDto & {
  metaImage?: string;
  type?: 'product';
  url?: string;
  rating?: {
    scale: number;
    count: number;
    value: number;
  };
  brand?: string;
  siteName?: string;
};

export type LayoutProps = React.PropsWithChildren<{
  pageMeta: PageMeta;
  storeCode: StoreCodes;
  categoryTree: CategoryListItemFromDBDto[];
  defaultFavouriteProducts: THit[];
  storeId: number;
  newsletterSubcriptionForm?: NonNullable<
    NonNullable<GetNewsletterSubscriptionFormQuery['store']>['newsletterSubscriptionForm']
  >;
  gtmData: {
    isActive: boolean;
    containerId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>;
  };
  storeData: StoreData;
  isDraftMode: boolean;
  cinematicMode?: boolean;
  currentCategoryPath?: number[];
  etrustedData?: TTrustedShopsRatingResponse | null;
  pageType?: 'catalog_category_view_new' | 'icp';
  promoBannerCmsBlock?: string;
}>;

const LastSeenProductsLazy = dynamic(() => import('@stories/organisms/LastSeenProducts'), { ssr: false });
const FeedbackFormLazy = dynamic(() => import('@stories/organisms/FeedbackForm'), { ssr: false });

const translations = {
  'Please select your options': {
    de_DE: 'Bitte wähle dein Geschmack aus'
  }
};

export default function Layout({
  categoryTree,
  children,
  currentCategoryPath,
  defaultFavouriteProducts,
  etrustedData,
  gtmData,
  isDraftMode,
  newsletterSubcriptionForm,
  pageMeta,
  pageType,
  storeCode,
  storeData,
  storeId,
  cinematicMode = false,
  promoBannerCmsBlock
}: LayoutProps) {
  const { productId } = useRouter().query;
  const t = useTranslate(translations);
  const [isNavigationShown, setNavigationShown] = useState(false);
  const [isNotificationProductShown, setNotificationProductShown] = useState(false);
  const [isFilterOverlayShown, setFilterOverlayShown] = useState(false);
  const [isOverlayShown, setOverlayShown] = useState(false);
  const [closeOverlayOnClick, setCloseOverlayOnClick] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [productConfigurationOverlayData, setProductConfigurationOverlayData] = useState<any>({});
  const [isProductConfigurationOverlayShown, setIsProductConfigurationOverlayShown] = useState(false);
  const [newFooterData, setNewFooterData] = useState<TNewFooter | undefined>(undefined);
  // const [errorMessageDisplayed] = useAtom(errorMessageDisplayedAtom);
  const [cart] = useCart();
  const router = useRouter();
  const { disableGTM, disableCM } = router.query;

  type callbackType = () => void;
  const [overlayClickCallbacks, setOverlayClickCallbacks] = useState<callbackType[]>([]);

  const faviconHref = FAVICONS?.[storeCode] || '';

  const showOverlay = ({
    callback,
    closeOnClick = true
  }: {
    callback?: () => void;
    closeOnClick?: boolean;
    disableBackButton?: boolean;
  }) => {
    setOverlayShown(true);
    setCloseOverlayOnClick(closeOnClick);
    if (callback !== undefined && typeof callback === 'function') {
      setOverlayClickCallbacks([...overlayClickCallbacks, callback]);
    }
  };

  const showProductConfiguration = (product, productOptions) => {
    sendGAEvent({ category: 'Product configuration overlay', action: 'open', label: product.id });
    setIsProductConfigurationOverlayShown(true);
    setProductConfigurationOverlayData({ product, productOptions });
    showOverlay({
      callback: () => {
        setIsProductConfigurationOverlayShown(false);
      }
    });
  };

  const hideOverlay = () => {
    setOverlayShown(false);
  };

  useEffect(() => {
    if (!isOverlayShown) {
      overlayClickCallbacks.forEach((callback) => callback());
      setOverlayClickCallbacks([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverlayShown]);

  const initalizeGTM = () => {
    if (gtmData.isActive) {
      TagManager.initialize({ gtmId: gtmData.containerId, dataLayer: gtmData.dataLayer });
      TagManager.dataLayer({
        dataLayerName: 'cartProducts',
        dataLayer: cart.products
      });
    }
  };

  useEffect(() => {
    setNewFooterData(storeData?.newFooter || undefined);
  }, [storeData]);

  useEffect(() => {
    router.events.on('routeChangeComplete', (url, { shallow }) => {
      if (!shallow) {
        hideOverlay();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (disableGTM) {
      case undefined:
        initalizeGTM();
      case 'defer':
        document.addEventListener('DOMContentLoaded', () => {
          console.log('GTM deferred loaded');
          initalizeGTM();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmData]); // no need to depend on cart, because it should be rendered once.

  return (
    <ThemeProvider theme={getThemeByStoreCode(storeCode)}>
      <Head>
        {getFontsUrlsByStoreCode(storeCode).map((fontUrl) => (
          <link key={fontUrl} rel="preload" href={fontUrl} as="font" />
        ))}
        {pageType && (
          <script
            id="vitafy-page-type"
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: `PAGE_TYPE="${pageType}";` }}
          />
        )}
        <title>{pageMeta.title}</title>
        <meta name="apple-mobile-web-app-title" content="Vitafy" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content={pageMeta.metaTitle ?? ''} />
        <meta name="description" content={pageMeta.metaDescription ?? ''} />
        <meta name="robots" content={pageMeta.metaRobots ?? ''} />
        <meta name="keywords" content={pageMeta.metaKeywords ?? ''} />
        <meta name="image" content={pageMeta.metaImage ?? ''} />
        {pageMeta.type === 'product' && (
          <>
            <meta property="og:type" content="product" />
            <meta property="og:title" content={pageMeta.title} />
            {pageMeta.metaDescription && (
              <meta property="og:description" content={pageMeta.metaDescription} />
            )}
            <meta property="og:image" content={pageMeta.metaImage} />
            <meta property="og:url" content={pageMeta.url} />
          </>
        )}
        <link rel="icon" href={faviconHref} type="image/x-icon" />
      </Head>
      {storeData.headJs?.scripts?.map((script) => {
        if (script.scriptType === 'inline') {
          const { scriptId, javascript } = script as { scriptId: string; javascript: string };
          return (
            <Script id={scriptId} key={script.id}>
              {javascript}
            </Script>
          );
        }
        if (script.scriptType === 'remote') {
          const { src, strategy } = script as { src: string; strategy: NextjsScriptLoadStrategy };
          return <Script key={script.id} strategy={strategy} src={src} />;
        }

        return null;
      })}
      <PageContext.Provider
        value={{
          categoryTree,
          cinematicMode,
          currentCategoryPath,
          etrustedData,
          hideOverlay,
          isFilterOverlayShown,
          isNavigationShown,
          isNotificationProductShown,
          numberOfLinesShownInProductHit: storeData.numberOfLines ?? 4,
          setFilterOverlayShown,
          setNavigationShown,
          setNotificationProductShown,
          showOverlay,
          showProductConfiguration,
          storeCode,
          storeId,
          centeredSearchBar: storeData?.centeredSearchBar || false,
          desktopNavigationNumberOfColumns: storeData?.desktopNavigationNumberOfColumns ?? 3,
          logo: storeData?.logo || undefined,
          formatPrice: () => 10,
          storeData: {
            activateWishlist: storeData?.activateWishlist || false,
            categoryHeroFallbackImage: storeData?.categoryHeroFallbackImage?.url || undefined,
            promoBannerCmsBlock
          }
        }}
      >
        <NotificationContextProvider>
          <CustomPriceContextProvider storeCode={storeCode} productId={Number(productId)}>
            {/* Preview mode badge */}
            {isDraftMode && (
              <div
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  zIndex: 9999,
                  backgroundColor: 'red',
                  color: 'white',
                  padding: '.5rem'
                }}
              >
                Preview Mode
                <a
                  href={`/api/disable-draft?path=${encodeURIComponent(router.asPath)}`}
                  style={{
                    display: 'block',
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: '0.8rem',
                    textAlign: 'center'
                  }}
                >
                  Exit Preview
                </a>
              </div>
            )}
            <Global styles={globalCss} />
            <DialogueOverlayProvider>
              <VoucherBar />
              {/*<NotificationProduct/>*/}
              <MobileNavigation categoryTree={categoryTree} />
              <SearchDropDown
                favouriteProducts={defaultFavouriteProducts}
                navigation={{ categoryTree }}
                topBar={{ topBar: storeData?.topBar }}
              />
              <NotificationProduct />
              <Overlay
                visible={isOverlayShown}
                clickHandler={() => {
                  if (closeOverlayOnClick === true) {
                    hideOverlay();
                  }
                }}
              />
              <main>{children}</main>
              {storeData.uspCardPack && <USPCardPack {...storeData.uspCardPack}></USPCardPack>}
              <LastSeenProductsLazy />
              <FeedbackFormLazy />
              {newsletterSubcriptionForm && <NewsletterSubscriptionLP {...newsletterSubcriptionForm} />}
              {newFooterData && <NewFooter {...newFooterData} />}
              {isProductConfigurationOverlayShown && (
                <ProductConfigurationOverlay
                  header={t('Please select your options')}
                  uncofiguredProductOptions={productConfigurationOverlayData?.productOptions}
                  product={productConfigurationOverlayData?.product}
                />
              )}
            </DialogueOverlayProvider>
          </CustomPriceContextProvider>
        </NotificationContextProvider>
      </PageContext.Provider>
      {!disableCM && (
        <Script
          id="consentmanager"
          src={`${ASSET_URL}/asset/scripts/consentManager.js`}
          strategy="lazyOnload"
        />
      )}
    </ThemeProvider>
  );
}
